<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="50%">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">进店登记信息</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-form class="form" label-position="right" label-width="120px" ref="form">
        <el-form-item label="客户姓名: ">{{listData.realname}}</el-form-item>
        <el-form-item label="手机电话: ">{{listData.mobile}}</el-form-item>
        <el-form-item label="销售顾问: ">
          <span
            v-if="$store.state.userList[listData.seller_uid] != undefined"
          >{{$store.state.userList[listData.seller_uid][0]}}</span>
        </el-form-item>
        <el-form-item label="来电/店: ">{{listData.income_type == '1' ? '来店' : '来电'}}</el-form-item>
        <el-form-item label="进店日期: ">{{listData.income_date}}</el-form-item>
        <el-form-item label="来访时间段: ">
          {{cfgData(listData.income_time_section)}}
        </el-form-item>
        <el-form-item label="进离店时间: ">{{listData.income_time_begin}} - {{listData.income_time_end}}</el-form-item>
        <el-form-item label="留店时长: ">{{listData.income_time_duration}} 分钟</el-form-item>
        <el-form-item label="来店人数: ">{{listData.income_number}} 人</el-form-item>
        <el-form-item label="客户类型: ">
          {{cfgData(listData.client_type)}}
        </el-form-item>
        <el-form-item label="微信号: ">{{listData.wechat}}</el-form-item>
        <el-form-item label="性别: ">{{listData.sex == '1' ? '男' : '女'}}</el-form-item>
        <el-form-item label="客户属性: ">{{listData.is_first_income == '1' ? '首次' : '再次'}}</el-form-item>
        <el-form-item label="区域: ">
          {{cfgData(listData.region)}}
        </el-form-item>
        <el-form-item label="是否入座: ">{{listData.has_seat == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否报价: ">{{listData.has_offer == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否重点客户: ">{{listData.is_vip == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否试驾: ">{{listData.has_trial == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="试驾备注: ">{{listData.trial_memo}}</el-form-item>
        <el-form-item label="现提车辆: ">{{listData.has_pick == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="来店目的: ">
          {{cfgData(listData.income_purpose)}}
        </el-form-item>
        <el-form-item label="意向车型: ">
          {{cfgData(listData.target_model)}} · {{cfgData(listData.attribute)}}
        </el-form-item>
        <el-form-item label="变速箱类型: ">
          {{cfgData(listData.transmission)}}
        </el-form-item>
        <el-form-item label="需求排量: ">
          {{cfgData(listData.displacement)}}
        </el-form-item>
        <el-form-item label="外观颜色: ">
          {{cfgData(listData.outer_color)}}
        </el-form-item>
        <el-form-item label="内饰颜色: ">
          {{cfgData(listData.inner_color)}}
        </el-form-item>
        <el-form-item label="线索渠道: ">
          {{cfgData(listData.clue_source)}}
        </el-form-item>
        <el-form-item label="营销分析: ">
          {{cfgData(listData.marketing_analysis)}}
        </el-form-item>
        <el-form-item label="客户类别: ">
          {{cfgData(listData.client_cate)}}
        </el-form-item>
        <el-form-item label="客户有效性: ">{{listData.has_effective == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="购车类型: ">
          <span
            v-if="$store.state.userList[listData.buy_type] != undefined"
          >{{$store.state.cfgData[listData.buy_type][0]}}</span>
        </el-form-item>
        <el-form-item label="二手车型: ">{{listData.used_model}}</el-form-item>
        <el-form-item label="二手车到店: ">{{listData.used_arrived == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="是否勘察: ">{{listData.used_surveyed == '1' ? '是' : '否'}}</el-form-item>
        <el-form-item label="评估师: ">{{listData.valuator}}</el-form-item>
        <el-form-item label="简述: ">{{listData.demand_memo}}</el-form-item>
      </el-form>
    </div>

    <div class="form-footer" v-has="'trace-create'">
      <div>
        <el-button
          @click="$router.push({ name: 'customer-tracking', query: {
                client_id: listData.client_id
              }})"
          type="primary"
        >马上跟踪</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { cfgById } from '@/unit/unit.js'
export default {
  data() {
    return {
      visible: false,
      listData: {}
    }
  },
  methods: {
    cfgData(cid){
      return cfgById(cid)
    },
    //添加
    view(id) {
      this.visible = true
      this.$axios
        .post('/income/detail', {
          sys_id: id
        })
        .then(res => {
          this.listData = res.data.data
        })
    }
  }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px;
  height: calc(100vh - 78px);
  overflow-y: auto;
}

.w100 {
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .el-form-item {
    width: 33.33%;
  }
  /deep/ .el-form-item__content {
    font-weight: 700;
  }
}

.form-footer {
  text-align: right;
  width: 50%;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>