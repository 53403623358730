<template>
  <div class="main" data-title="进店客户列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">进店客户列表</h2>
      </div>
    </div>

    <el-card class="main-content" shadow="hover">
      <el-form class="form">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="销售顾问">
              <el-select clearable filterable placeholder="请选择" v-model="seachKey.seller_uid">
                <template v-for="(item, key) in $store.state.userList">
                  <el-option
                    :key="key"
                    :label="item[0]"
                    :value="parseInt(key)"
                    v-if="item[3] == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户姓名">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="seachKey.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="手机号码">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="seachKey.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="进店日期">
              <el-date-picker
                align="right"
                end-placeholder="结束日期"
                format="yyyy年MM月dd日"
                range-separator="-"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                v-model="seachKey.income_date_begin_end"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="意向车型">
              <el-cascader
                :options="carTypeOptions()"
                :props="{ checkStrictly: true }"
                @change="changeCarType"
                clearable
                v-model="car_type"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item class="label-block" label=" ">
              <el-button
                @click="getListData(1)"
                icon="el-icon-search"
                type="primary"
              >搜索</el-button>
              <el-button @click="resetSeachKey">重置</el-button>
              <el-button @click="expan = true" type="text" v-if="!expan">
                展开
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-button @click="expan = false" type="text" v-else>
                收起
                <i class="el-icon-arrow-up"></i>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="expan">
          <el-col :span="4">
            <el-form-item label="线索渠道">
              <el-select placeholder="请选择" v-model="seachKey.clue_source">
                <template v-for="(item, index) in $store.state.cfgList.channel">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="购车类型">
              <el-select placeholder="请选择" v-model="seachKey.buy_type">
                <template v-for="(item, index) in $store.state.cfgList.buyTimes">
                  <el-option
                    :key="index"
                    :label="item.f1"
                    :value="item.sys_id"
                    v-if="item.status == 1"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">
          进店客户列表
          <span class="totle">共{{total}}条</span>
          <span class="totle">首次{{total - first_income_total}}条</span>
          <span class="totle">再次{{first_income_total}}条</span>
        </div>
        <div class="jue-card-btns">
          <el-button
            @click="$router.push({name: 'customer-register'})"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-has="'income-create'"
          >客户登记</el-button>
          <span class="jue-divider"></span>
          <export-income :searchKey = seachKey></export-income>
        </div>
      </div>

      <div class="no-data" v-if="total == 0">暂无数据</div>

      <div class="table-wrapper" v-else>
        <el-table :data="listData" :style="'width: '+$store.state.tableWidth" class="table">
          <el-table-column fixed label="进店日期" prop="income_date" width="100"></el-table-column>
          <el-table-column fixed label="客户姓名" prop="realname" width="100"></el-table-column>
          <el-table-column fixed label="手机电话" prop="mobile" width="120"></el-table-column>
          <el-table-column label="销售顾问" width="100">
            <template
              slot-scope="scope"
              v-if="$store.state.userList[scope.row.seller_uid] != undefined"
            >{{$store.state.userList[scope.row.seller_uid][0]}}</template>
          </el-table-column>
          <el-table-column label="来电/店" width="100">
            <template slot-scope="scope">{{scope.row.income_type == '1' ? '来店' : '来电'}}</template>
          </el-table-column>
          <el-table-column label="来访时间段" prop="income_time_section" width="120">
            <template slot-scope="scope">
              <span
                v-if="scope.row.income_time_section"
              >{{ $store.state.cfgData[scope.row.income_time_section][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="进离店时间" width="120">
            <template slot-scope="scope">
              <span>{{scope.row.income_time_begin}}</span> -
              <span>{{scope.row.income_time_end}}</span>
            </template>
          </el-table-column>
          <el-table-column label="留店时长" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.income_time_duration}}</span>分钟
            </template>
          </el-table-column>
          <el-table-column label="来店人数" prop="income_number" width="100"></el-table-column>
          <el-table-column label="客户类型" prop="client_type" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.client_type"
              >{{ $store.state.cfgData[scope.row.client_type][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="微信号" prop="wechat" width="120"></el-table-column>
          <el-table-column label="性别" prop="sex">
            <template slot-scope="scope">{{scope.row.sex == '1' ? '男' : '女'}}</template>
          </el-table-column>
          <el-table-column label="客户属性" prop="is_first_income" width="100">
            <template slot-scope="scope">{{scope.row.is_first_income == '1' ? '首次' : '再次'}}</template>
          </el-table-column>
          <el-table-column label="区域" prop="region">
            <template slot-scope="scope">
              <span v-if="scope.row.region != 0">{{ $store.state.cfgData[scope.row.region][0] }}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column label="是否入座" prop="has_seat" width="100">
            <template slot-scope="scope">{{scope.row.has_seat == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否报价" prop="has_offer" width="100">
            <template slot-scope="scope">{{scope.row.has_offer == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否重点客户" prop="is_vip" width="130">
            <template slot-scope="scope">{{scope.row.is_vip == '1' ? '是' : '否'}}</template>
          </el-table-column>

          <el-table-column label="是否试驾" prop="has_trial" width="100">
            <template slot-scope="scope">{{scope.row.has_trial == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="现提车辆" prop="has_pick" width="100">
            <template slot-scope="scope">{{scope.row.has_pick == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column
            label="试驾备注"
            prop="trial_memo"
            show-overflow-tooltip
           
            width="100"
          ></el-table-column>

          <el-table-column label="来店目的" prop="income_purpose" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.income_purpose"
              >{{ $store.state.cfgData[scope.row.income_purpose][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="意向车型" prop="target_model" width="250">
            <template slot-scope="scope">
              <span
                v-if="scope.row.target_model"
              >{{ $store.state.cfgData[scope.row.target_model][0] }}</span>
              <span v-if="scope.row.attribute">· {{ $store.state.cfgData[scope.row.attribute][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="变速箱类型" prop="transmission" width="120">
            <template slot-scope="scope">
              <span
                v-if="scope.row.transmission"
              >{{ $store.state.cfgData[scope.row.transmission][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="需求排量" prop="displacement" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.displacement"
              >{{ $store.state.cfgData[scope.row.displacement][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="外观颜色" prop="outer_color" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.outer_color"
              >{{ $store.state.cfgData[scope.row.outer_color][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="内饰颜色" prop="inner_color" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.inner_color"
              >{{ $store.state.cfgData[scope.row.inner_color][0] }}</span>
            </template>
          </el-table-column>

          <el-table-column label="线索渠道" prop="clue_source" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.clue_source"
              >{{ $store.state.cfgData[scope.row.clue_source][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="营销分析" prop="marketing_analysis" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.marketing_analysis"
              >{{ $store.state.cfgData[scope.row.marketing_analysis][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户类别" prop="client_cate" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.client_cate"
              >{{ $store.state.cfgData[scope.row.client_cate][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户有效性" prop="has_effective" width="120">
            <template slot-scope="scope">{{scope.row.has_effective == '1' ? '有效' : '无效'}}</template>
          </el-table-column>
          <el-table-column label="购车类型" prop="buy_type" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.buy_type">{{ $store.state.cfgData[scope.row.buy_type][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="二手车型" prop="used_model" width="100"></el-table-column>
          <el-table-column label="二手车到店" prop="used_arrived" width="120">
            <template slot-scope="scope">{{scope.row.used_arrived == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否勘察" prop="used_surveyed" width="100">
            <template slot-scope="scope">{{scope.row.used_surveyed == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="评估师" prop="valuator" width="100"></el-table-column>
          <el-table-column label="简述" prop="demand_memo" show-overflow-tooltip></el-table-column>

          <el-table-column fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button
                @click="$router.push({ name: 'customer-tracking', query: {
                client_id: scope.row.client_id
              }})"
                size="small"
                type="text"
                v-has="'trace-create'"
              >跟踪</el-button>
              <span class="jue-divider" v-has="'trace-create'"></span>
              <el-button
                @click="view(scope.row.sys_id)"
                size="small"
                type="text"
                v-has="'income-detail'"
              >查看</el-button>
              <span class="jue-divider" v-has="'income-detail'" v-if="scope.row.trace_count == 0"></span>
              <el-button
                @click="$router.push({name: 'customer-register', query: {sys_id: scope.row.sys_id}})"
                size="small"
                type="text"
                v-has="'income-edit'"
                v-if="scope.row.trace_count == 0"
              >修改</el-button>
              <span class="jue-divider" v-has="'income-edit'" v-if="scope.row.trace_count == 0"></span>
              <el-button
                @click="deleteItem(scope.row)"
                class="btn-delete"
                size="small"
                type="text"
                v-has="'income-remove'"
                v-if="scope.row.trace_count == 0"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :page-size="seachKey.count"
            :total="total"
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <change-seller @getListData="getListData(1)" ref="changeSeller"></change-seller>
    <income-detail ref="incomeDetail"></income-detail>
  </div>
</template>
<script>
import ChangeSeller from '@/components/ChangeSeller.vue'
import IncomeDetail from '@/components/IncomeDetail.vue'
import ExportIncome from '@/components/ExportExcel/ExportIncome.vue'
import { getCarType } from '@/unit/unit.js'
export default {
  components: {
    ChangeSeller,
    IncomeDetail,
    ExportIncome,
  },
  data() {
    return {
      listData: [],
      cfgData: {},
      total: 0,
      first_income_total: 0,
      multipleSelection: [],
      expan: false, //搜索展开
      options: {},
      car_type: '',
      seachKey: {
        seller_uid: '',
        realname: '',
        mobile: '',
        clue_source: '',
        buy_type: '',
        target_model: '',
        attribute: '',
        has_pick: '',
        trace_count: 0,

        page: 1,
        count: 15,
        income_date_begin_end: [],
      },
    }
  },
  computed: {
    timeDefault() {
      var date = new Date()
      let yyyy = date.getFullYear()
      let MM =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let dd = date.getDate()
      var s1 = yyyy + '-' + MM + '-' + dd
      return s1
    },
  },
  mounted() {
    //设置搜索条件，进店日期为今日
    this.seachKey.income_date_begin_end = [this.timeDefault, this.timeDefault]
    this.init()
  },
  methods: {
    changeCarType() {
      if (this.car_type.length == 0) {
        this.seachKey.target_model = ''
        this.seachKey.attribute = ''
      }
      if (this.car_type.length == 1) {
        this.seachKey.target_model = this.car_type[0]
        this.seachKey.attribute = ''
      }
      if (this.car_type.length == 2) {
        this.seachKey.target_model = this.car_type[0]
        this.seachKey.attribute = this.car_type[1]
      }
    },
    carTypeOptions() {
      return getCarType()
    },
    init() {
      // if (!this.$route.query.type) {
      //   this.$router.push({ query: { type: 'list' } })
      // }

      //获取数据
      this.getListData(1)
    },

    view(id) {
      this.$refs.incomeDetail.view(id)
    },
    //点击分页
    handleCurrentChange(val) {
      // this.seachKey.page = val
      this.getListData(val)
    },

    //重置搜索条件
    resetSeachKey() {
      this.car_type = ''
      this.seachKey = {
        is_vip: 0,
        status: 0,
        realname: '',
        mobile: '',
        clue_source: '',
        buy_type: '',
        target_model: '',
        has_pick: '',
        trace_count: 0,

        page: 1,
        count: 15,
        income_date_begin_end: [],
      }
    },

    //获取列表数据
    getListData(page) {

      for (var propName in this.seachKey) {
        if (this.seachKey[propName] === '') {
          delete this.seachKey[propName]
        }
      }
      if (this.seachKey.income_date_begin_end) {
        this.seachKey.income_date_begin = this.seachKey.income_date_begin_end[0]
        this.seachKey.income_date_end = this.seachKey.income_date_begin_end[1]
      }

      this.seachKey.page = page

      this.$axios.post('/income/lists', this.seachKey).then((res) => {
        if (res.data.code == 200) {
          this.listData = res.data.data.list
          // this.$message.success(res.data.msg)
          this.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    deleteItem(item) {
      this.$confirm(
        '此操作将永久删除 ' + item.realname + '  , 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.$axios
            .post('/income/remove', {
              sys_id: item.sys_id,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getListData(1)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.footer {
  text-align: right;
  padding: 20px 0;
}

.form {
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}
</style>